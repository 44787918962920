.dynamic {
  position: sticky;
  top: 0;
  z-index: 10;
}

.non-editable-input {
  border: 0px; /* Không có border khi không chỉnh sửa */
}

.editable-input {
  border: 1px solid blue; /* Hiển thị border khi đang chỉnh sửa */
  outline: none; /* Tùy chọn bỏ outline mặc định */
}

.groupTile {
  padding: 2rem;
  gap: 1.2rem;
  width: 100%;
  --neutral-disable-background-color: transparent;
}

.groupTile.selected {
  background-color: var(--neutral-selected-background-color);
}

.groupTile>div:has(input:disabled) {
  border-color: transparent;
}