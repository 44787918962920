.tab-setting-container {
    width: 100%;
    gap: 2.4rem;
    padding: 0 2.4rem;
    border-bottom: var(--neutral-bolder-border-color);
}

.tab-setting-container>.btn-tab {
    padding: 0.8rem 0;
    transition: opacity 0.4s ease-in-out;
    border-bottom: 2px solid transparent;
}

.tab-setting-container>.btn-tab.selected {
    color: var(--primary-main-color);
    opacity: 1;
    border-color: var(--primary-main-color);
}

div:has(>.button-add-chart) {
    position: relative;
}

.button-add-chart {
    position: absolute;
    bottom: 2.4rem;
    right: 2.4rem;
    z-index: 9;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    background-color: var(--neutral-absolute-background-color);
}

.demo-container {
    height: 36rem;
    border-radius: 0.8rem;
    --gutter: 2.4rem;
    position: relative;
    background-color: var(--neutral-bolder-background-color)
}

.demo-container>div:first-child {
    pointer-events: none;
}

.demo-container>div:last-child {
    opacity: 0;
    justify-content: end;
    align-items: start;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    gap: 0.8rem;
    border-radius: 0.8rem;
    padding: 1.6rem;
    transition: opacity 0.1s ease-in-out;
}

.demo-container:hover>div:last-child {
    opacity: 1;
}

.list-form-container,
.list-card-container {
    flex: 1;
    flex-wrap: wrap;
    padding: 3.2rem;
    gap: 2.4rem;
    background-color: var(--neutral-lighter-background-color);
    height: 100%;
    overflow: hidden auto;
    align-content: start;
    align-items: start;
}

.input-card-name:has(input:disabled) {
    border-color: transparent;
    background-color: transparent !important;
}

.overview-card-container {
    gap: 1.6rem;
    --gutter: 0rem;
    overflow: hidden auto;
    height: 100%;
    padding: 0 1.6rem;
}

.button-select-relative-data {
    border: var(--neutral-bolder-border-color);
    border-style: dashed;
    padding: 1.6rem;
    border-radius: 0.8rem;
    gap: 0.8rem;
    pointer-events: all;
}

.button-select-relative-data>div>div>div[class="comp-text  label-3"] {
    color: var(--primary-main-color);
}

.layer-tile {
    gap: 0.4rem;
    padding: 0.8rem 0.4rem;
    border-radius: 0.8rem;
}

.layer-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.layer-tile:hover {
    background-color: var(--neutral-hover-background-color);
}

.demo-component-in-container {
    background-color: var(--primary-main-color);
    border-radius: 0.1rem;
}