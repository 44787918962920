.module-tile {
    gap: 1.2rem;
    padding: 0.9rem 1.2rem;
    border-radius: 0.8rem;
}

.module-tile div[class*="label-3"]:has(> input) {
    flex: 1;
    width: 100%;
    padding: 0 0.4rem;
    height: 2.4rem;
    border-radius: 0.4rem;
    text-align: start;
    --neutral-disable-background-color: #00000000;
    text-overflow: ellipsis;
}

.module-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.module-tile>.prefix {
    width: 2rem;
    height: 2rem;
}

.sidebar {
    position: sticky;
    top: 5.6rem;
    height: calc(100dvh - 5.6rem);
    width: 29.6rem;
    border-right: var(--neutral-bolder-border-color);
    background-color: var(--neutral-main-background-color);
    z-index: 8;
}

.sidebar>div:first-child {
    padding: 1.6rem 0;
    border-bottom: var(--neutral-bolder-border-color);
    margin: 0 1.6rem;
    width: calc(100% - 3.2rem);
}

.sidebar>.module-list-container {
    flex: 1;
    overflow: hidden auto !important;
    padding: 0.8rem 1.6rem;
}

.sidebar>div:last-child {
    padding: 1.6rem 0;
    border-top: var(--neutral-bolder-border-color);
    margin: 0 1.6rem;
    width: calc(100% - 3.2rem);
    gap: 0.8rem;
}

.header {
    top: 0;
    position: sticky;
    width: 100vw;
    height: 5.6rem;
    padding: 1.2rem 2.4rem;
    gap: 1.6rem;
    border-bottom: var(--neutral-bolder-border-color);
    background-color: var(--neutral-main-background-color);
    z-index: 9;
}

.sidebar~div {
    flex: 1;
    height: calc(100dvh - 5.6rem);
    min-height: 40rem;
}

.header-search {
    height: 3.2rem;
    background-color: #fff;
    width: 40%;
    position: sticky;
    left: 50%;
    transform: translateX(-50%);
    padding: 0 1.2rem;
    border: var(--neutral-bolder-border-color);
    gap: 1.2rem;
    border-radius: 10rem;
    color: var(--neutral-text-subtitle-color);
}

div[class*="main-layout"]:has(> .handle-demo-page) div[id]:not(div[component-type="Container"]) * {
    pointer-events: none !important;
}

div[class*="main-layout"]:has(> .handle-demo-page) {
    position: relative;
}

.handle-demo-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    pointer-events: none;
}

.account-actions {
    max-height: fit-content;
    width: 16rem;
    --neutral-hover-background-color: var(--primary-main-color)
}

.account-actions>button:hover * {
    color: var(--neutral-text-stable-color);
    --color: var(--neutral-text-stable-color);
}

@keyframes collaspe-sidebar {
    from {
        width: 29.6rem;
        --name-opacity: 1;
    }

    to {
        width: 8rem;
        --name-opacity: 0;
        --display: none;
    }
}

@keyframes expand-sidebar {
    from {
        width: 8rem;
        --name-opacity: 0;
        --display: none;
    }

    to {
        width: 29.6rem;
        --name-opacity: 1;
    }
}

div[class*="main-layout"]>.sidebar {
    animation: expand-sidebar 0.6s ease-in-out;
    animation-fill-mode: forwards;
}

div[class*="main-layout"]:has(>div[class*="full-view"])>.sidebar {
    animation: collaspe-sidebar 0.6s ease-in-out;
    animation-fill-mode: forwards;
}

.sidebar .module-tile>div[class*="label-3"] {
    opacity: var(--name-opacity);
    display: var(--display, initial);
}

.sidebar>div:last-child div[class*="comp-text"] {
    opacity: var(--name-opacity);
    display: var(--display, initial);
}

.sidebar .module-tile>div:last-child:has(>svg) {
    display: var(--display, initial);
}