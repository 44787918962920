.capture-container {
    position: relative;
}

.capture-container>div {
    width: fit-content;
    height: fit-content;
    transform-origin: top left;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}