div[class*="popup-header"]>.btn-tab {
    padding: 0.5rem 1rem;
    border-radius: 0.4rem;
}

div[class*="popup-header"]>.btn-tab.selected {
    background-color: var(--neutral-selected-background-color);
}

.setting-page-body {
    position: relative;
    flex: 1;
    height: 100%;
    width: 100%;
    padding: 2.4rem
}

.setting-page-body * {
    cursor: context-menu !important;
}

.setting-page-body iframe {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.demo-component-in-container {
    background-color: var(--primary-main-color);
    border-radius: 0.1rem;
}

.page-tile {
    padding: 0.4rem;
    border-radius: 0.8rem;
    width: 100%;
}

.page-tile>*:first-child {
    flex: 1;
    width: 100%;
}

.page-tile>button {
    visibility: hidden;
}

.page-tile:hover {
    background-color: var(--neutral-hover-background-color);
}

.page-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.page-tile.selected>button {
    visibility: visible;
}

.layer-list-container {
    background-color: #14181b;
    flex: 1;
    overflow: hidden auto;
    scrollbar-width: none;
    padding: 0.4rem;
    gap: 0.4rem;
}

.layer-tile {
    gap: 0.4rem;
    padding: 0.4rem;
    border-radius: 0.8rem;
}

.layer-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.layer-tile:hover {
    background-color: var(--neutral-hover-background-color);
}

.layer-tile>div:has(>input) {
    border-color: transparent;
    padding: 0.4rem;
    flex: 1;
    --neutral-disable-background-color: transparent !important;
}

.layout-model {
    --gutter: 1.6rem;
    gap: 0.8rem;
    cursor: pointer;
}

.layout-model * {
    pointer-events: none;
}

.layout-model>div:last-child {
    border: var(--neutral-bolder-border-color);
    position: relative;
    height: 24rem;
    border-radius: 0.4rem;
    padding: 1.6rem;
}

.layout-model.selected>div:last-child {
    border-color: var(--primary-main-color);
}