img[alt="Empty-project"] {
    content: url('../../../assets/EmptyPage.svg');
}

.project-card {
    --gutter: 2.4rem;
    border-radius: 0.8rem;
    border: var(--neutral-bolder-border-color);
    position: relative;
}

.project-card>.logo-img {
    background-color: var(--neutral-main-background-color);
    width: 100%;
    height: 10rem;
    padding: 1.6rem;
    justify-content: center;
}

.project-card .logo-img:hover>img {
    transition: scale ease-in-out 0.2s;
    scale: 1.1;
}

.project-card div:has(>input) {
    padding: 0;
    border-radius: 0.2rem;
    --neutral-disable-background-color: transparent !important;
}

.project-card div:has(input:disabled) {
    border-color: transparent;
}

.project-card div>input:hover {
    text-decoration: underline;
}

.project-card div[class*="menu-dots"] {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    display: none;
}

.project-card:hover div[class*="menu-dots"] {
    display: flex;
}