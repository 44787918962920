.icon-button60 {
    width: 6rem;
    height: 6rem;
    justify-content: center;
    --size: 4rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button48 {
    width: 4.8rem;
    height: 4.8rem;
    justify-content: center;
    --size: 2.4rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button40 {
    width: 4rem;
    height: 4rem;
    justify-content: center;
    --size: 2rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button32 {
    width: 3.2rem;
    height: 3.2rem;
    justify-content: center;
    --size: 2rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button28 {
    width: 2.8rem;
    height: 2.8rem;
    justify-content: center;
    --size: 1.6rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button24 {
    width: 2.4rem;
    height: 2.4rem;
    justify-content: center;
    --size: 1.4rem !important;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button20 {
    width: 2rem;
    height: 2rem;
    justify-content: center;
    --size: 1.4rem;
    --color: var(--neutral-text-subtitle-color);
}

.icon-button16 {
    width: 1.6rem;
    height: 1.6rem;
    justify-content: center;
    --size: 1.2rem;
    --color: var(--neutral-text-subtitle-color);
}

*[class*="icon-button"] * {
    --size: inherit !important;
    --color: inherit !important;
}

.button-grey {
    padding: 0.5rem 1rem;
    background-color: var(--neutral-main-background-color);
    gap: 0.8rem;
    border-radius: 0.6rem;
    justify-content: center;
    width: fit-content;
}

.button-grey * {
    color: var(--neutral-text-subtitle-color) !important;
    --color: var(--neutral-text-subtitle-color) !important;
}

.button-primary {
    gap: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    background-color: var(--primary-main-color) !important;
    justify-content: center;
    width: fit-content;
    border: 1px solid var(--primary-main-color) !important;
}

.button-primary * {
    color: var(--neutral-text-stable-color) !important;
    --color: var(--neutral-text-stable-color) !important;
}

.button-neutral {
    gap: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    justify-content: center;
    width: fit-content;
    border: var(--neutral-bolder-border-color);
}

.button-neutral * {
    color: var(--neutral-text-subtitle-color) !important;
}

.button-disabled,
.tag-disabled {
    color: var(--neutral-text-disabled-color) !important;
    background-color: var(--neutral-main-background-color);
    gap: 0.8rem;
    padding: 0.5rem 1rem;
    border-radius: 0.6rem;
    width: fit-content;
    justify-content: center;
    pointer-events: none !important;
}

.button-disabled *,
.tag-disabled * {
    color: var(--neutral-text-disabled-color) !important;
    --color: var(--neutral-text-disabled-color) !important;
}

.button-disabled.border,
.tag-disabled.border {
    border: var(--neutral-bolder-border-color);
}

.button-infor,
.tag-infor {
    color: var(--infor-main-color) !important;
    background-color: var(--infor-background) !important;
    padding: 0.5rem 1rem;
    gap: 0.8rem;
    border-radius: 0.6rem;
    width: fit-content;
    justify-content: center;
}

.button-infor *,
.tag-infor * {
    color: var(--infor-main-color) !important;
    --color: var(--infor-main-color) !important;
}

.button-infor.border,
.tag-infor.border {
    border: 1px solid var(--infor-border-color) !important;
}

.button-warning,
.tag-warning {
    color: var(--warning-main-color) !important;
    background-color: var(--warning-background);
    padding: 0.5rem 1rem;
    gap: 0.8rem;
    border-radius: 0.6rem;
    width: fit-content;
    justify-content: center;
}

.button-warning *,
.tag-warning * {
    color: var(--warning-main-color) !important;
}

.button-warning.border,
.tag-warning.border {
    border: 1px solid var(--warning-border-color);
}

.button-success,
.tag-success {
    color: var(--success-main-color) !important;
    background-color: var(--success-background);
    padding: 0.5rem 1rem;
    gap: 0.8rem;
    border-radius: 0.6rem;
    width: fit-content;
    justify-content: center;
}

.button-success *,
.tag-success * {
    color: var(--success-main-color) !important;
}

.button-success.border,
.tag-success.border {
    border: 1px solid var(--success-border-color);
}

.col.divider {
    margin: 1.6rem 0;
    height: 1px;
    background-color: #d7d7db;
}

.row.divider {
    margin: 0 1.6rem;
    width: 1px;
    background-color: #d7d7db;
}

.picker-time-hm-container>.input-time-container {
    background-color: var(--neutral-absolute-background-color);
    height: 3.2rem;
    padding: 0.4rem 1.2rem;
    border-radius: 0.4rem
}

.ckeditor-form .ck-content p,
strong,
i {
    font-size: 1.6rem;
}

.ckeditor-form .ck-content h1 {
    font-size: 3rem;
}

.ckeditor-form .ck-content h2 {
    font-size: 2.4rem;
}

.ckeditor-form .ck-content h3 {
    font-size: 2rem;
}

.ckeditor-form .ck-content h4 {
    font-size: 1.6rem;
}

.input-range-container:has(.helper-text) {
    overflow: visible;
}

.input-range-container>*:has(.helper-text) {
    overflow: visible;
}

.input-range-container:has(.helper-text) .date-picker-container,
.text-field-container {
    border-color: #e14337;
}

.auto-complete-input-container {
    height: 4rem;
    position: relative;
    gap: 0.8rem;
    border-radius: 0.8rem;
    border: var(--neutral-bolder-border-color);
    padding: 1rem 1.2rem;
}

.auto-complete-input-container>input {
    flex: 1;
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding: 0;
    background-color: transparent !important;
    font: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    font-weight: inherit;
    line-height: inherit;
    text-align: inherit;
    text-overflow: inherit;
}

.auto-complete-input-option-container {
    background-color: var(--neutral-absolute-background-color);
    border-radius: 0.8rem;
    position: absolute;
    border: var(--neutral-bolder-border-color);
    max-width: 20rem;
    max-height: 20rem;
    overflow: hidden auto !important;
    scrollbar-width: none;
    z-index: 90
}

.auto-complete-input-option {
    padding: 0.8rem 1.6rem;
}

.auto-complete-input-option:hover,
.auto-complete-input-option.selected {
    background-color: var(--neutral-main-background-color);
}

.svg-container>svg {
    width: 100%;
    height: 100%;
}

.table {
    width: 100%;
    flex: 1;
    height: 100%;
    scrollbar-width: thin;
    overflow: auto !important;
}

.table>div>div {
    min-height: 5.6rem;
    padding: 0.8rem 1.6rem;
    gap: 1.2rem;
    min-width: 8.8rem;
    background-color: var(--neutral-absolute-background-color);
}

.table>div {
    overflow: visible;
    min-width: 100%;
}

.table {
    width: 100%;
    flex: 1;
    height: 100%;
    scrollbar-width: thin;
    overflow: auto !important;
}

.table .table-row>div,
.table>.header>div {
    min-height: 5.6rem;
    padding: 0.8rem 1.6rem;
    gap: 1.2rem;
    min-width: 8.8rem;
    background-color: var(--neutral-absolute-background-color);
}

.table .table-row,
.table>.header {
    overflow: visible;
    min-width: 100%;
    align-items: stretch;
    width: max-content;
}

.table>.header {
    position: sticky;
    top: 0;
    z-index: 3;
}

.table>.header>div {
    min-height: 4rem;
    height: 4rem;
    background-color: var(--neutral-lighter-background-color);
    padding: 0 1.6rem;
    gap: 1.2rem;
    min-width: 8.8rem;
    border-top: var(--neutral-bolder-border-color);
    border-bottom: var(--neutral-bolder-border-color);
    cursor: context-menu;
}

.table>.header * {
    color: var(--neutral-text-subtitle-color);
}

.table .table-row>div:first-child, .table>.header>div:first-child {
    position: sticky;
    left: 0;
    z-index: 2;
    min-width: 16rem;
    padding-left: 2.4rem;
    box-shadow: var(--shadow-right);
}

.table .table-row>div:last-child, .table>.header>div:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    box-shadow: var(--shadow-left);
}

.table>.header>div button:last-child {
    border-radius: 0.4rem;
    background-color: var(--neutral-hover-background-color);
}

.table .table-row:hover>div {
    background-color: var(--neutral-bolder-background-color);
}

.table>.header>div:hover {
    background-color: var(--neutral-bolder-background-color);
}

.sort {
    display: none;
}

.table>.header>div:hover .sort {
    display: flex;
}


.table>.header>div:hover button:last-child {
    background-color: var(--neutral-absolute-background-color);
}