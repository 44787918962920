.stage-list {
    height: 100%;
    overflow: hidden auto;
    padding: 2.4rem;
    gap: 4px;
    width: 32rem;
    border-right: var(--neutral-bolder-border-color);
}

.stage-item {
    padding: 1.6rem 2.4rem;
    width: 100%;
    border-radius: 0.8rem;
    border: var(--neutral-bolder-border-color);
    cursor: pointer;
}

.stage-item.selected {
    background: var(--neutral-main-background-color);
    pointer-events: none;
}

.stage-item>div:first-child {
    gap: 4px;
    flex: 1;
}

.stage-list>button:last-child {
    gap: 1.4rem;
    justify-content: center;
    border-radius: 0.8rem;
    border: var(--neutral-bolder-border-color);
    border-style: dashed;
    width: 100%;
    padding: 1.4rem
}

.stage-work-space {
    flex: 1;
    height: 100%;
    width: 100%;
    overflow: auto auto !important;
    scrollbar-width: thin;
    align-items: start;
    padding: 6rem;
    gap: 4rem;
    background-image: radial-gradient(#cfcbcb 10%, #f9f8f8 10%);
    background-position: 0.4rem 0.4rem;
    background-size: 2.2rem 2.2rem;
    background-repeat: repeat;
}

.stage-section {
    width: 39.8rem;
    background-color: var(--neutral-absolute-background-color);
    border: var(--neutral-bolder-border-color);
    border-radius: 0.8rem;
}

.stage-section:not(.selected) {
    position: relative;
}

.stage-section:not(.selected)::after {
    content: "";
    position: absolute;
    z-index: 2;
    background-color: #ffffff99;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.stage-section.selected {
    border-color: var(--primary-main-color);
}

.stage-section>.header {
    padding: 1.6rem 2.4rem;
    border-bottom: var(--neutral-bolder-border-color);
    gap: 1.2rem;
}

.stage-section>.body {
    padding: 1.6rem 2.4rem;
    min-height: 24rem;
    gap: 1.6rem;
}

.stage-section>.footer {
    gap: 0.8rem;
    padding: 1.6rem 2.4rem;
    border-top: var(--neutral-bolder-border-color);
}

.option-form-item {
    height: 28rem;
    border-radius: 0.8rem;
    --gutter: 2.4rem;
    background-color: var(--neutral-bolder-background-color);
    border: var(--neutral-bolder-border-color);
    cursor: pointer;
    position: relative;
}

.option-form-item::after {
    content: "";
    position: absolute;
    z-index: 2;
    background-color: #ffffff66;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.option-form-item.selected {
    border-color: var(--primary-main-color);
}