.setting-key-table {
    padding: 0 2.4rem;
    overflow: auto hidden;
    scrollbar-width: thin;
    width: 100%
}

.setting-key-table>div>* {
    padding: 0 1.2rem;
}

.setting-key-table>div>*:nth-child(n+1):nth-child(-n+3) {
    position: sticky;
    z-index: 2;
    background-color: #fff;
}

.setting-key-table>div:first-child>* {
    padding: 1.2rem;
    background-color: var(--neutral-lighter-background-color) !important;
    border-top: var(--neutral-bolder-border-color);
    border-bottom: var(--neutral-bolder-border-color);
    height: 4.8rem;
}

.form-setting-container:not(.form-setting-container:has(>.component-form-setting-block:nth-child(2))) .simple-setting>button {
    pointer-events: none;
    background-color: var(--neutral-disable-background-color);
}

.component-form-setting-block {
    padding: 2rem;
    border: var(--neutral-bolder-border-color);
    border-radius: 0.8rem;
    cursor: pointer;
    align-items: stretch;
}

.component-form-setting-block.selected {
    border-color: var(--primary-main-color);
}

.component-form-setting-block>*:first-child {
    pointer-events: none;
}