.init-dashboard-block {
    --gutter: 0px;
    padding: 3.2rem 2.4rem;
    border-radius: 1.2rem;
    gap: 2.4rem;
    align-items: center;
    background-color: var(--background-grey2);
}

.button-init-dashboard {
    padding: 2.4rem 0.8rem;
    justify-content: center;
    border-radius: 1.2rem;
    background-color: #fff;
    gap: 1.2rem;
}

.card-option {
    --gutter: 1.6rem;
    gap: 0.4rem;
    align-items: center;
    border-radius: 0.8rem;
    border: var(--neutral-bolder-border-color);
    padding: 2.4rem;
}

.card-option.selected {
    background-color: var(--infor-background);
    border-color: var(--infor-main-color);
}

.card-option>.chart-icon {
    justify-content: center;
    width: 5.6rem;
    height: 5.6rem;
    border-radius: 50%;
    background-color: var(--infor-background);
    margin-bottom: 1.2rem;
}

.step-container {
    gap: 0.8rem;
    align-items: center;
}

.step-container>.step-item {
    gap: 0.8rem;
}

.step-container>.step-item>.row {
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    color: #161C2452;
    background-color: #C0CBD81F;
    text-align: center;
}

.step-container.active>.step-item>.row {
    color: #fff;
    background-color: var(--primary-main-color);
}

.step-container>.step-item>.line {
    width: 8rem;
    height: 2px;
    background-color: #161C2414;
}

.step-container.active>.step-item>.line {
    background-color: var(--primary-main-color);
}

.step-container.selected>.step-item>.line:last-child {
    background-color: #161C2414;
}

.step-container>.comp-text {
    color: #161C2452;
}

.step-container.active>.comp-text {
    color: #161C24;
}