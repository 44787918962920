.tabbar {
    gap: 0.8rem;
    padding: 0.9rem 0;
    border-bottom: 2px inset transparent;
    cursor: pointer;
    transition: border-color 0.2s ease-in-out;
}

.tabbar.selected {
    border-color: var(--primary-main-color);
    cursor: context-menu;
}

.tabbar.selected * {
    transition: color, --color 0.2s ease-in-out;
}

.tabbar.selected * {
    color: var(--primary-main-color);
    --color: var(--primary-main-color);
}