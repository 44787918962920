.component-options-group {
    flex-wrap: wrap;
    gap: 1.2rem;
    padding: 0.4rem 1.2rem 1.2rem;
    align-items: stretch;
    border-bottom: var(--neutral-bolder-border-color);
}

.component-options {
    --gutter: 1.2rem;
    gap: 0.8rem;
    border-radius: 0.8rem;
    border: var(--neutral-main-border-color);
    padding: 0.8rem;
    justify-content: space-between
}

.component-options>div[class*="comp-text"] {
    width: 100%;
    text-align: center
}

.component-options>div * {
    pointer-events: none;
}

.component-options:hover {
    cursor: pointer;
    background-color: var(--neutral-hover-background-color);
}

.layer-list-container {
    flex: 1;
    overflow: hidden auto;
    padding: 0.4rem;
    gap: 0.4rem;
}

.setting-card-item-container {
    background-color: #ffff;
    flex: 1;
}

.setting-card-item-container .overview-container {
    height: 100%;
    gap: 0.8rem;
    padding: 0.8rem 2.4rem;
    flex: 1;
}

.overview-container>div:last-child {
    flex: 1;
    min-height: 40rem;
    position: relative;
    overflow: visible;
    height: 100%;
}

.overview-container>div:last-child>div {
    flex: 1;
    position: relative;
    overflow: visible;
    border-radius: 0.8rem;
    border: var(--neutral-main-border-color);
    border-width: 4px;
    height: 100%;
    align-items: center;
}

.overview-container>div:last-child>div>div {
    max-width: 70rem;
    width: 100%;
    min-height: 100%;
}

.overview-container>div[hover-name]:not(*:has(>.dragging)):before {
    content: attr(hover-name);
    position: absolute;
    top: var(--hoverY);
    left: var(--hoverX);
    transform: translateY(-100%);
    padding: 0.2rem;
    color: #fff;
    background-color: #9c6334;
    border-radius: 0.2rem;
    font-size: 1rem;
    z-index: 1;
    pointer-events: none;
}

.overview-container>div[hover-name]:not(*:has(>.dragging)):after {
    content: "";
    position: absolute;
    top: var(--hoverY);
    left: var(--hoverX);
    width: var(--hoverW);
    height: var(--hoverH);
    box-sizing: border-box;
    border: 1px solid #9c6334;
    pointer-events: none;
}

.overview-container>div:last-child>div[selected-name]:not(.dragging):before {
    content: attr(selected-name);
    position: absolute;
    top: var(--selectedY);
    transform: translateY(-100%);
    left: var(--selectedX);
    padding: 0.2rem;
    color: #fff;
    background-color: #349c90;
    border-radius: 0.2rem;
    font-size: 1rem;
    z-index: 2;
    pointer-events: none;
}

.overview-container>div:last-child>div[selected-name]:not(.dragging):after {
    content: "";
    position: absolute;
    top: var(--selectedY);
    left: var(--selectedX);
    width: var(--selectedW);
    height: var(--selectedH);
    box-sizing: border-box;
    border: 1px solid #349c90;
    z-index: 2;
    pointer-events: none;
}

.setting-card-item-container .infor-container {
    overflow: hidden auto;
    height: 100%;
    border-left: var(--neutral-bolder-border-color);
    width: 29.8rem;
}

.infor-container div[class*="regular1"]:has(input) {
    border: var(--neutral-main-border-color);
    padding: 0.5rem 1rem;
}

.setting-card-tabbar {
    width: 100%;
    gap: 0.8rem;
    border-bottom: var(--neutral-main-border-color);
    border-bottom-style: inset;
}

.setting-card-tabbar>div {
    font-weight: 400;
    color: var(--neutral-text-subtitle-color);
    border-bottom: 2px solid transparent;
    padding: 0.8rem 0.8rem 0.4rem;
}

.setting-card-tabbar>div.selected {
    font-weight: 600;
    color: var(--neutral-text-label-color);
    border-bottom-color: var(--primary-main-color);
}