@import url(https://cdn.jsdelivr.net/gh/WiniGit/web-component@latest/src/skin/root.css);
@import url(https://cdn.jsdelivr.net/gh/WiniGit/web-component@latest/src/skin/typography.css);
@import url(https://cdn.jsdelivr.net/gh/WiniGit/web-component@latest/src/skin/layout.css);
@import url('./assets/skin/root.css');
@import url('./project-component/project-component.css');

html,
body,
#root {
    width: 100vw;
    height: 100dvh;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    overflow: hidden;
}

body * {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    font-family: 'Inter';
}

body * {
    background-color: transparent;
    outline: none;
    padding: 0;
}

button,
input,
a {
    text-decoration: none;
    outline: none;
    padding: 0;
    border: none;
}

button,
button * {
    cursor: pointer;
}

.main-layout {
    width: 100vw;
    height: 100dvh;
    overflow: hidden auto !important;
}

.main-layout.row {
    flex-wrap: wrap;
    align-items: start !important;
}

.main-layout.col>.layout-body {
    flex: 1;
}

::-webkit-scrollbar {
    width: 0.6rem;
}

/* Track */
::-webkit-scrollbar-track {
    border-radius: 0.4rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 0.4rem;
    background-color: #c1c1c1;
    visibility: hidden;
}

*:hover::-webkit-scrollbar-thumb {
    visibility: visible;
}

.page-container::-webkit-scrollbar,
.main-layout::-webkit-scrollbar {
    width: 0.8rem;
}

.table-view {
    flex: 1;
    height: 100%;
}

.breadcrumb {
    gap: 1.2rem;
}

.breadcrumb>a:hover {
    text-decoration: underline;
}

.breadcrumb>.fa-chevron-right {
    font-size: 1.2rem;
}

.popup-actions {
    padding: 0.4rem 0;
    max-height: 28rem;
    min-width: 10rem;
    background-color: var(--neutral-absolute-background-color);
}

.popup-actions>button {
    padding: 0.8rem;
    gap: 0.8rem;
}

.popup-actions>button * {
    color: var(--neutral-text-title-color);
    --color: var(--neutral-text-subtitle-color);
}

.popup-actions>button:disabled * {
    color: var(--neutral-text-disabled-color);
    --color: var(--neutral-text-disabled-color);
}

.popup-actions>button:hover {
    background-color: var(--neutral-hover-background-color);
}

.popup-container {
    transition: width 0.8s ease-in-out, max-width 0.8s ease-in-out, height 0.8s ease-in-out, max-height 0.8s ease-in-out, border-radius 0.8s ease-in-out;
}

.svg-inline--fa {
    color: var(--neutral-text-subtitle-color);
}

.demo-sort {
    height: 0.2rem;
    width: 100%;
    background-color: var(--primary-main-color);
}

.col>.remain {
    flex: 1 !important;
    height: 100% !important;
}