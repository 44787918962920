.setting-column-tile {
    gap: 0.8rem;
    padding: 0.4rem 1.6rem;
}

.setting-column-tile>div:first-child {
    cursor: pointer;
}

.setting-column-tile:first-child>div:first-child {
    pointer-events: none;
}

.setting-column-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.setting-column-tile div:has(> input[type="text"]) {
    flex: 1;
    width: 100%;
    padding: 0.4rem;
    height: 3.2rem;
    border-radius: 0.4rem;
    text-align: start;
    border-color: transparent;
    text-overflow: ellipsis;
}

.setting-column-tile div:has(> input[type="text"]):focus-within {
    border-color: var(--focus-border);
}

body:has(>.resize-cell-stick) *:not(.resize-cell-stick) {
    pointer-events: none;
    user-select: none;
}

.resize-cell-stick {
    position: fixed;
    width: 8px;
    border-left: 2px solid var(--neutral-bolder-background-color);
    border-right: 2px solid var(--neutral-bolder-background-color);
    background-color: var(--primary-main-color);
    box-sizing: border-box;
    height: 4rem;
    z-index: 10;
    cursor: ew-resize;
}

.html-value-cell * {
    text-align: justify;
    font-size: inherit;
    line-height: inherit;
    color: inherit;
    margin: 0;
    padding: 0;
    width: 100%;
}

.custom-option-title {
    padding: 0.8rem 1.6rem;
    gap: 0.8rem;
    width: 100%;
    cursor: context-menu;
    width: 100%;
}

.custom-option-title:hover {
    background-color: var(--neutral-hover-background-color);
}