.setting-page-header {
    height: 4.8rem;
    width: 100%;
    background-color: #14181b;
    border-bottom: 1px solid #444444;
}

.setting-page-sidebar.left {
    height: 100%;
    min-width: 24rem !important;
    max-width: 44.8rem !important;
    width: 32rem;
}

.setting-page-sidebar.right {
    height: 100%;
    width: 30rem;
    border-left: 2px solid #323b45;
}

.setting-page-sidebar.left>div:last-child {
    background-color: #323b45;
    height: 100%;
    width: 0.6rem;
    align-items: center;
    justify-content: center;
}

.setting-page-sidebar.left>div:last-child::after {
    content: "";
    background-color: #9aa6b6;
    height: 3.2rem;
    border-radius: 2rem;
    width: 0.3rem;
}

.setting-page-sidebar.left>div:last-child:hover {
    cursor: e-resize;
}

.action-tab {
    gap: 0.4rem;
    padding: 0.8rem;
    border-bottom: 1px solid #444444;
}

.action-tab>button {
    border-radius: 0.2rem;
    padding: 0.4rem 0.6rem;
}

.action-tab>button:hover,
.action-tab>button.selected {
    background-color: #383838;
}

.page-list-conatiner {
    max-height: calc(100% - 6rem);
    height: 14rem;
    min-height: 6rem;
    max-height: 50%;
}

.page-list-conatiner>.col {
    overflow: hidden auto;
    flex: 1;
    height: 100%;
    scrollbar-width: none;
}

.page-list-conatiner>div:last-child {
    background-color: #323b45;
    width: 100%;
    height: 0.6rem;
    justify-content: center;
    border-bottom-left-radius: 0.1rem;
    border-bottom-right-radius: 0.1rem;
    cursor: n-resize;
}

.page-list-conatiner>div:last-child::after {
    content: "";
    background-color: #9aa6b6;
    width: 2rem;
    border-radius: 1rem;
    height: 0.3rem;
}

.page-container {
    width: 100%;
    height: 100%;
}

.setting-page-sidebar.right input {
    min-width: 0;
}

.setting-page-sidebar.right .regular1:has(input) {
    border-color: #444444;
    padding: 0.5rem 1rem;
}

.setting-page-sidebar .text-field-container:focus-within,
.setting-page-sidebar .select1-container:focus-within {
    border-color: var(--primary-main-color);
}

.select-unit-options {
    border-radius: 0.2rem !important;
    width: 3.6rem !important;
}

.select-unit-options,
.select-unit-options * {
    padding: 0 !important;
    min-width: fit-content !important;
    min-height: fit-content !important;
}

.select-unit-options .select-tile {
    padding: 0.4rem 0.6rem !important;
    background-color: #14181b;
}

.select-unit-options .select-tile:hover {
    background-color: #383838 !important;
}

.select-unit-options .select-tile * {
    color: #fff !important;
}

.select1-container.regular1,
.select1-container.regular1 * {
    cursor: context-menu;
}

body>.dragging {
    display: none !important;
    z-index: 11 !important;
}