.card-service {
    --gutter: min(10.4rem, 9.2%);
    gap: 2rem;
    border-radius: 0.4rem;
    padding: 3.6rem;
    align-items: center;
}

.card-service:nth-child(odd) {
    background-color: #fff;
}

.card-service:nth-child(even) {
    background-color: var(--primary-main-color);
}

.card-service .media {
    padding: 0 0.4rem 2rem 0.4rem;
    border-bottom: 2px solid #E0E3EB;
    width: fit-content
}