.text-align-options {
    border-radius: 0.6rem;
    border: var(--neutral-main-border-color);
    justify-content: space-between
}

.text-align-options>div {
    padding: 0.6rem 0.8rem;
    border-radius: 0.4rem;
}

.text-align-options>div.selected {
    background-color: var(--primary-main-color);
    --color: var(--neutral-text-stable-color);
    pointer-events: none;
}