.demo-admin-assets {
    gap: 0.4rem;
}

.demo-admin-assets>img {
    background-color: var(--neutral-bolder-background-color);
    border-radius: 0.8rem;
    height: 25.6rem;
    width: 100%;
    object-fit: contain;
}

.list-form-container {
    flex: 1;
    flex-wrap: wrap;
    padding: 3.2rem;
    gap: 2.4rem;
    background-color: var(--neutral-lighter-background-color);
    height: 100%;
    overflow: hidden auto;
    align-content: start;
    align-items: start;
}

.input-form-name:has(input:disabled) {
    border-color: transparent;
    background-color: transparent !important;
}


.setting-form-item-container {
    background-color: #ffff;
    flex: 1;
}

.setting-form-item-container .overview-container {
    --gutter: 2.4rem;
    height: 100%;
    pointer-events: none;
    position: relative;
    gap: 1.6rem;
}

.setting-form-item-container .overview-container::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 3.8rem);
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.8rem;
}

.setting-form-item-container .infor-container {
    --gutter: 2.4rem;
    overflow: hidden auto;
    height: 100%;
    padding: 0 1.2rem;
    border-left: var(--neutral-bolder-border-color);
    gap: 1.6rem
}

.setting-prop-tile {
    gap: 0.8rem;
    padding: 0.8rem 1.6rem;
    border-radius: 0.4rem;
}

.setting-prop-tile>div:first-child {
    visibility: hidden;
    pointer-events: none;
}

.setting-prop-tile.selected {
    background-color: var(--neutral-selected-background-color);
}

.setting-prop-tile.selected>div:first-child {
    cursor: pointer;
    visibility: visible;
    pointer-events: all;
}

.setting-prop-tile div[class*="comp-text"] {
    flex: 1;
    width: 100%;
    padding: 0.4rem;
    height: 3.2rem;
    border-radius: 0.4rem;
    text-align: start;
    text-overflow: ellipsis;
    cursor: context-menu;
}