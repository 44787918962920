.flex-direction-options {
    border-radius: 0.6rem;
    border: var(--neutral-main-border-color);
    justify-content: space-between
}

.flex-direction-options>div {
    padding: 0.6rem 0.8rem;
    border-radius: 0.4rem;
}

.flex-direction-options>div.selected {
    background-color: var(--primary-main-color);
    --color: var(--neutral-text-stable-color);
    pointer-events: none;
}

.alignment-options {
    flex-wrap: wrap;
    border-radius: 0.8rem;
    border: var(--neutral-main-border-color);
    flex: 1
}

.alignment-options>button {
    width: 33.3%;
    padding: 0.6rem
}

.alignment-options>button>div {
    width: 1rem;
    height: 1rem;
    border-radius: 0.4rem;
    border: var(--neutral-main-border-color);
    background-color: var(--neutral-bolder-background-color);
}

.alignment-options>button:hover>div {
    border: var(--neutral-bolder-border-color);
    border-width: 2px;
    background-color: var(--neutral-bolder-background-color);
}

.alignment-options>button.selected>div {
    border-width: 2px;
    border-color: var(--primary-main-color);
    background-color: transparent;
    pointer-events: none;
}

.toggle-padding {
    padding: 0.5rem;
    border-radius: 0.4rem;
    border: var(--neutral-main-border-color)
}


.group-padding {
    flex-wrap: wrap;
    gap: 0.8rem;
}

.group-padding > div:has(>input) {
    width: calc((100% - 2.4rem - 1.6rem - 2px) / 2);
}