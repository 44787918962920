.card-container[class*="col"] {
    gap: 1.6rem;
}

.card-container .card-thumbnail-container {
    margin-top: 1.6rem;
    object-fit: cover;
    object-position: center;
}

.card-container .card-thumbnail-container>img {
    transition: scale ease-in-out 0.2s;
    scale: 1.1;
}

.chart-container {
    gap: 1.2rem;
}

.form-container {
    border-radius: 0.8rem;
    background-color: var(--neutral-absolute-background-color);
    border: var(--neutral-bolder-border-color);
}

.form-container>.form-header {
    padding: 1.6rem 2.4rem;
    gap: 0.8rem;
    border-bottom: var(--neutral-bolder-border-color);
}

.form-container>.form-body {
    flex: 1;
    gap: 1.6rem;
    padding: 1.6rem 2.4rem;
    overflow: hidden auto;
}

.form-container>.form-footer {
    padding: 1.6rem 2.4rem;
    gap: 0.8rem;
    justify-content: end;
    border-top: var(--neutral-bolder-border-color);
}