.assets-options-container {
    background-color: #14181b;
    flex: 1;
    overflow: hidden auto;
    scrollbar-width: none;
}

.component-options-group {
    flex-wrap: wrap;
    gap: 1.2rem;
    padding: 0.4rem 1.2rem;
    align-items: stretch
}

.component-options {
    --gutter: 1.2rem;
    gap: 0.8rem;
    border-radius: 0.8rem;
    border: 1px solid #444444;
    padding: 0.8rem;
    justify-content: space-between
}

.component-options>.comp-text {
    width: 100%;
    text-align: center
}

.component-options>div * {
    pointer-events: none;
}

.component-options:hover {
    cursor: pointer;
    background-color: #383838;
}

.module-card-tile {
    padding: 1.2rem 1.6rem;
    gap: 1rem;
    border-bottom: 1px solid #444444
}

.module-card-tile:hover {
    background-color: #383838;
}

.card-option-demo {
    --gutter: 1.2rem;
    cursor: pointer;
    border-radius: 1.2rem;
    border: 1px solid #444444;
    padding: 1.2rem;
    position: relative;
    height: 16rem;
}

.card-option-demo>div * {
    pointer-events: none !important;
}

body>.component-options.card-option-demo {
    padding: 0;
    width: 24rem;
}