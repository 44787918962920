.color-picker {
    width: 1.4rem !important;
    height: 1.4rem !important;
    flex: none !important;
    border-radius: 0.2rem !important;
    border: var(--neutral-main-border-color);
}

.color-opacity {
    padding: 0.4rem !important;
    padding-left: 0.6rem !important;
    border-left: var(--neutral-main-border-color) !important;
    flex: none !important;
    width: 3.2rem !important;
}

.color-picker-container>div:has(>svg):hover {
    background-color: var(--neutral-hover-background-color);
    border-radius: 0.4rem;
}